@use '../utils';

/* Only available in React SDK. */
.str-chat {
  /* The background color of the component */
  --str-chat__message-notification-background-color: var(--str-chat__primary-color);

  /* Top border of the component */
  --str-chat__message-notification-border-block-start: none;

  /* Bottom border of the component */
  --str-chat__message-notification-border-block-end: none;

  /* Left (right in RTL layout) border of the component */
  --str-chat__message-notification-border-inline-start: none;

  /* Right (left in RTL layout) border of the component */
  --str-chat__message-notification-border-inline-end: none;

  /* The border radius used for the borders of the component */
  --str-chat__message-notification-border-radius: 50px;

  /* Box shadow applied to the component */
  --str-chat__message-notification-box-shadow: none;

  /* The text/icon color of the component */
  --str-chat__message-notification-color: var(--str-chat__on-primary-color);
}

.str-chat__message-notification {
  @include utils.component-layer-overrides('message-notification');
  font: var(--str-chat__caption-text);
  cursor: pointer;
}
