@use '../utils';

/* Only available in Angular SDK. */
.str-chat {
  /* The color of the stepper icon. */
  --str-chat__image-carousel-stepper-color: var(--str-chat__text-color);
}

@media only screen and (max-device-width: 768px) {
  .str-chat__message {
    .str-chat__attachment-list {
      .str-chat__modal--open {
        .str-chat__modal__inner {
          border-radius: 0;
        }
      }
    }
  }
}

.str-chat__image-carousel {
  .str-chat__image-carousel-stepper {
    --str-chat-icon-color: var(--str-chat__image-carousel-stepper-color);
    cursor: pointer;

    svg path {
      fill: var(--str-chat__image-carousel-stepper-color);
    }
  }
}
