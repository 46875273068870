/* Only available in React SDK. */
.str-chat {
  --str-chat-selector-background-color: var(--str-chat__secondary-background-color);
  --str-chat-selector-border-color: var(--str-chat__surface-color);

  --str-chat-selector-button-color-default: var(--str-chat__text-low-emphasis-color);
  --str-chat-selector-button-color-selected: var(--str-chat__text-color);
  --str-chat-selector-button-background-color-default: transparent;
  --str-chat-selector-button-background-color-selected: var(--str-chat__surface-color);
}

.str-chat__chat-view {
  &__selector {
    border-right: 1px solid var(--str-chat-selector-border-color);
    background-color: var(--str-chat-selector-background-color);

    &-button {
      --str-chat-icon-color: var(--str-chat-selector-button-color-default);
      
      border: none;
      background: var(--str-chat-selector-button-background-color-default);
      color: var(--str-chat-selector-button-color-default);


      &[aria-selected='true'] {
        --str-chat-icon-color: var(--str-chat-selector-button-color-selected);
        color: var(--str-chat-selector-button-color-selected);
        background: var(--str-chat-selector-button-background-color-selected);
      }
    }
  }
}
