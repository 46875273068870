@use '../utils';

.str-chat {
  /* The border radius used for the borders of the component */
  --str-chat__channel-border-radius: 0;

  /* The text/icon color of the component */
  --str-chat__channel-color: var(--str-chat__text-color);

  /* The background color of the component */
  --str-chat__channel-background-color: var(--str-chat__background-color);

  /* Box shadow applied to the component */
  --str-chat__channel-box-shadow: none;

  /* Top border of the component */
  --str-chat__channel-border-block-start: none;

  /* Bottom border of the component */
  --str-chat__channel-border-block-end: none;

  /* Left (right in RTL layout) border of the component */
  --str-chat__channel-border-inline-start: none;

  /* Right (left in RTL layout) border of the component */
  --str-chat__channel-border-inline-end: none;

  /* The icon color used when no channel is selected */
  --str-chat__channel-empty-indicator-color: var(--str-chat__disabled-color);

  /* The text color used when no channel is selected */
  --str-chat__channel-empty-color: var(--str-chat__text-low-emphasis-color);

  /* The color of the loading indicator */
  --str-chat__channel-loading-state-color: var(--str-chat__disabled-color);
}

.str-chat__channel {
  @include utils.component-layer-overrides('channel');
}

.str-chat__empty-channel {
  --str-chat-icon-color: var(--str-chat__channel-empty-color);
  @include utils.component-layer-overrides('channel');
  @include utils.empty-theme('channel');

  .str-chat__empty-channel-text {
    color: var(--str-chat__channel-empty-color);
  }
}

.str-chat__loading-channel {
  @include utils.loading-animation;

  .str-chat__loading-channel-header {
    background-color: var(--str-chat__channel-header-background-color);

    .str-chat__loading-channel-header-avatar {
      @include utils.loading-item-background('channel');
      border-radius: var(--str-chat__avatar-border-radius);
    }

    .str-chat__loading-channel-header-name,
    .str-chat__loading-channel-header-info {
      @include utils.loading-item-background('channel');
      border-radius: var(--str-chat__border-radius-xs);
    }
  }

  .str-chat__loading-channel-message-list {
    background-color: var(--str-chat__message-list-background-color);

    .str-chat__loading-channel-message-avatar {
      @include utils.loading-item-background('channel');
      border-radius: var(--str-chat__avatar-border-radius);
    }

    .str-chat__loading-channel-message-sender,
    .str-chat__loading-channel-message-text,
    .str-chat__loading-channel-message-date {
      @include utils.loading-item-background('channel');
      border-radius: var(--str-chat__message-bubble-border-radius);
    }
  }

  .str-chat__loading-channel-message-input-row {
    .str-chat__loading-channel-message-input,
    .str-chat__loading-channel-message-send {
      @include utils.loading-item-background('channel');
    }

    .str-chat__loading-channel-message-input {
      border-radius: var(--str-chat__message-textarea-border-radius);
    }

    .str-chat__loading-channel-message-send {
      border-radius: var(--str-chat__message-send-border-radius);
    }
  }
}
