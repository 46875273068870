.calendar-top {
  display: flex;
  background: white;
  .cal-date-title {
    margin: auto;
    &.title {
      margin-bottom: auto !important;
    }
  }
  .cal-button {
    border: none;
    color: $cc-green;
    font-weight: 800;
  }
}

.calendar-listview {
  display: flex;
  .cal-month-view {
    width: 100%;
  }
  mwl-calendar-month-view-header {
    display: none;
  }
  .cal-cell-row {
    display: contents;
    .cal-day-cell {
      width: 100%;
      overflow: visible;
      height: unset;
    }
  }
  .cal-day-cell {
    display: none;
    &.cal-has-events {
      display: inline-flex;
      &.cal-past {
        display: none;
      }
    }
  }
}

.columns {
  @media screen and (max-width: 768px) {
    margin: 0 !important;
  }
}

.cal-month-view {
  background: white;
  font-size: 12px;
  .cal-day-cell {
    // height: 100px;
    // overflow: hidden;
    &.cal-today {
      background-color: white;
      .cal-day-number {
        font-size: 1.2em;
        opacity: 1;
      }
    }
    &.cal-has-events {
      cursor: unset !important;
      .cal-day-badge {
        background-color: $cc-green;
      }
    }
  }
}

.cal-week-view,
.cal-day-view {
  .cal-event {
    background-color: $cc-green-light;
    border-color: $cc-green;
    color: $cc-gray-blue;
    white-space: normal;
  }
}

@media only screen and (max-width: 768px) {
  .calendar-monthview .cal-month-view {
    .cal-has-events {
      .cal-cell-top {
        background-color: $cc-green-light;
      }
      .cal-cell-top .cal-day-badge {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  .calendar-top {
    border-top: 0.25em solid $cc-green;
  }
}

@media only screen and (max-width: 1088px) {
  .calendar-monthview .cal-month-view {
    .cal-header {
      background: $cc-gray-ultralight;
    }
    .cal-cell-top,
    .cal-day-cell {
      min-height: unset;
    }
    .cal-has-events .cal-cell-bottom {
      display: none;
    }
    .cal-day-cell {
      .cal-day-number {
        opacity: 1;
      }
      &.cal-past .cal-day-number {
        color: #aaaaaa;
      }
      &.cal-today {
        .cal-cell-top {
          background-color: $cc-yellow !important;
        }
        .cal-day-number {
          color: white;
        }
        &:hover {
          .cal-cell-top {
            background-color: $cc-yellow !important;
          }
          .cal-day-number {
            color: $cc-gray-blue;
          }
        }
      }
    }
  }
}
