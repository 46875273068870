.str-chat {
  /* The color of the loading indicator */
  --str-chat__loading-indicator-color: var(--str-chat__primary-color);
}

.str-chat__loading-indicator {
  svg {
    linearGradient {
      stop:last-child {
        stop-color: var(--str-chat__loading-indicator-color);
      }
    }
  }
}
