.modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  visibility: hidden;
}

.modal .modal-background {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

.modal.is-active {
  visibility: visible;
}

.modal.is-active .modal-background {
  opacity: 1;
}

.modal.modal-full-screen .modal-background {
  background-color: #fff;
}

.modal.modal-full-screen .modal-content {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  margin: 0;
  background-color: #fff;
}

.modal.modal-fx-fadeInScale .modal-content {
  -webkit-transform: scale(0.7);
  -o-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.modal.modal-fx-fadeInScale.is-active .modal-content {
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.modal.modal-fx-slideRight .modal-content {
  -webkit-transform: translateX(20%);
  -o-transform: translateX(20%);
  transform: translateX(20%);
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  -o-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.modal.modal-fx-slideRight.is-active .modal-content {
  -webkit-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.modal.modal-fx-slideLeft .modal-content {
  -webkit-transform: translateX(-20%);
  -o-transform: translateX(-20%);
  transform: translateX(-20%);
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  -o-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.modal.modal-fx-slideLeft.is-active .modal-content {
  -webkit-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.modal.modal-fx-slideBottom .modal-content {
  -webkit-transform: translateY(20%);
  -o-transform: translateY(20%);
  transform: translateY(20%);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.modal.modal-fx-slideBottom.is-active .modal-content {
  -webkit-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.modal.modal-fx-slideTop .modal-content {
  -webkit-transform: translateY(-20%);
  -o-transform: translateY(-20%);
  transform: translateY(-20%);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.modal.modal-fx-slideTop.is-active .modal-content {
  -webkit-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.modal.modal-fx-fall.modal {
  -webkit-perspective: 1300px;
  perspective: 1300px;
}

.modal.modal-fx-fall .modal-content {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(600px) rotateX(20deg);
  transform: translateZ(600px) rotateX(20deg);
  opacity: 0;
}

.modal.modal-fx-fall.is-active .modal-content {
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transform: translateZ(0) rotateX(0);
  transform: translateZ(0) rotateX(0);
  opacity: 1;
}

.modal.modal-fx-slideFall.modal {
  -webkit-perspective: 1300px;
  perspective: 1300px;
}

.modal.modal-fx-slideFall .modal-content {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translate(30%) translateZ(600px) rotate(10deg);
  transform: translate(30%) translateZ(600px) rotate(10deg);
  opacity: 0;
}

.modal.modal-fx-slideFall.is-active .modal-content {
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transform: translate(0) translateZ(0) rotate(0);
  transform: translate(0) translateZ(0) rotate(0);
  opacity: 1;
}

.modal.modal-fx-newsPaper .modal-content {
  -webkit-transform: scale(0) rotate(720deg);
  -o-transform: scale(0) rotate(720deg);
  transform: scale(0) rotate(720deg);
  opacity: 0;
}

.modal.modal-fx-newsPaper .modal-background,
.modal.modal-fx-newsPaper .modal-content {
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}

.modal.modal-fx-newsPaper.is-active .modal-content {
  -webkit-transform: scale(1) rotate(0);
  -o-transform: scale(1) rotate(0);
  transform: scale(1) rotate(0);
  opacity: 1;
}

.modal.modal-fx-3dFlipVertical.modal {
  -webkit-perspective: 1300px;
  perspective: 1300px;
}

.modal.modal-fx-3dFlipVertical .modal-content {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-70deg);
  transform: rotateX(-70deg);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

.modal.modal-fx-3dFlipVertical.is-active .modal-content {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  opacity: 1;
}

.modal.modal-fx-3dFlipHorizontal.modal {
  -webkit-perspective: 1300px;
  perspective: 1300px;
}

.modal.modal-fx-3dFlipHorizontal .modal-content {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateY(-70deg);
  transform: rotateY(-70deg);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

.modal.modal-fx-3dFlipHorizontal.is-active .modal-content {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
  opacity: 1;
}

.modal.modal-fx-3dSign.modal {
  -webkit-perspective: 1300px;
  perspective: 1300px;
}

.modal.modal-fx-3dSign .modal-content {
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-60deg);
  transform: rotateX(-60deg);
  -webkit-transform-origin: 50% 0;
  -o-transform-origin: 50% 0;
  transform-origin: 50% 0;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.modal.modal-fx-3dSign.is-active .modal-content {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  opacity: 1;
}

.modal.modal-fx-3dSignDown.modal {
  -webkit-perspective: 1300px;
  perspective: 1300px;
}

.modal.modal-fx-3dSignDown .modal-content {
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(60deg);
  transform: rotateX(60deg);
  -webkit-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.modal.modal-fx-3dSignDown.is-active .modal-content {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  opacity: 1;
}

.modal.modal-fx-superScaled .modal-content {
  -webkit-transform: scale(2);
  -o-transform: scale(2);
  transform: scale(2);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.modal.modal-fx-superScaled.is-active .modal-content {
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.modal.modal-fx-3dSlit.modal {
  -webkit-perspective: 1300px;
  perspective: 1300px;
}

.modal.modal-fx-3dSlit .modal-content {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(-3000px) rotateY(90deg);
  transform: translateZ(-3000px) rotateY(90deg);
  opacity: 0;
}

.modal.modal-fx-3dSlit.is-active .modal-content {
  -webkit-animation: slit 0.5s forwards ease-out;
  -o-animation: slit 0.5s forwards ease-out;
  animation: slit 0.5s forwards ease-out;
}

@-webkit-keyframes slit {
  50% {
    -webkit-transform: translateZ(-250px) rotateY(89deg);
    transform: translateZ(-250px) rotateY(89deg);
    opacity: 1;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
    opacity: 1;
  }
}

@-o-keyframes slit {
  50% {
    transform: translateZ(-250px) rotateY(89deg);
    opacity: 1;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    transform: translateZ(0) rotateY(0);
    opacity: 1;
  }
}

@keyframes slit {
  50% {
    -webkit-transform: translateZ(-250px) rotateY(89deg);
    transform: translateZ(-250px) rotateY(89deg);
    opacity: 1;
    -webkit-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
    opacity: 1;
  }
}

.modal.modal-fx-3dRotateFromBottom.modal {
  -webkit-perspective: 1300px;
  perspective: 1300px;
}

.modal.modal-fx-3dRotateFromBottom .modal-content {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateY(100%) rotateX(90deg);
  transform: translateY(100%) rotateX(90deg);
  -webkit-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.modal.modal-fx-3dRotateFromBottom.is-active .modal-content {
  -webkit-transform: translateY(0) rotateX(0);
  transform: translateY(0) rotateX(0);
  opacity: 1;
}

.modal.modal-fx-3dRotateFromLeft.modal {
  -webkit-perspective: 1300px;
  perspective: 1300px;
}

.modal.modal-fx-3dRotateFromLeft .modal-content {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  -webkit-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.modal.modal-fx-3dRotateFromLeft.is-active .modal-content {
  -webkit-transform: translateZ(0) translateX(0) rotateY(0);
  transform: translateZ(0) translateX(0) rotateY(0);
  opacity: 1;
}

.modal .modal-content.is-huge {
  width: 100%;
  margin: 0 20px;
  max-height: calc(100vh - 40px);
}

@media screen and (min-width: 769px) {
  .modal .modal-content.is-huge {
    width: 100%;
    margin: 0 20px;
    max-height: calc(100vh - 40px);
  }
}

.modal .modal-content.is-tiny {
  max-width: 400px;
}

.modal .modal-content.is-image {
  text-align: center;
}

.modal .modal-content.is-image img {
  max-height: calc(100vh - 50px);
}

.modal.modal-pos-top {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.modal.modal-pos-top .modal-content {
  margin-top: 1rem;
}

.modal.modal-pos-bottom {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.modal.modal-pos-bottom .modal-content {
  margin-bottom: 1rem;
}
