// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;

// Note: Color palettes are generated from primary: #79DBC0
//I actually updated the primary palette using chat GPT to lighten it to #4cc2ac on the 40 (container color)
$_palettes: (
  primary: (
    0: #000000,
    10: #00453d,
    20: #005b53,
    25: #00655c,
    30: #017067,
    35: #028a78,
    40: #3aa18e,
    50: #56b8a3,
    60: #6bc4b1,
    70: #86d6c2,
    80: #a1e8d3,
    90: #bdf2e4,
    95: #dbfbed,
    98: #effef8,
    99: #f6fefb,
    100: #ffffff,

  ),
  secondary: (
    0: #000000,
    10: #072019,
    20: #1d352e,
    25: #284039,
    30: #344c44,
    35: #3f574f,
    40: #4b635b,
    50: #637c74,
    60: #7d968d,
    70: #97b1a7,
    80: #b2ccc2,
    90: #cde9de,
    95: #dbf7ec,
    98: #e6fff5,
    99: #f3fff9,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #001e2d,
    20: #0f3447,
    25: #1c3f52,
    30: #294a5e,
    35: #35566a,
    40: #416277,
    50: #5a7b91,
    60: #7495ab,
    70: #8eb0c7,
    80: #a9cbe3,
    90: #c5e7ff,
    95: #e4f3ff,
    98: #f5faff,
    99: #fbfcff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #191c1b,
    20: #2e312f,
    25: #393c3a,
    30: #444846,
    35: #505351,
    40: #5c5f5d,
    50: #747876,
    60: #8e918f,
    70: #a9aca9,
    80: #c4c7c4,
    90: #e1e3e0,
    95: #eff1ee,
    98: #f8faf7,
    99: #ffffff,
    100: #ffffff,
    4: #0b0f0e,
    6: #111413,
    12: #1d201f,
    17: #272b29,
    22: #323634,
    24: #363a38,
    87: #d8dbd8,
    92: #e6e9e6,
    94: #eceeec,
    96: #f2f4f1,
  ),
  neutral-variant: (
    0: #000000,
    10: #141d1a,
    20: #29322f,
    25: #343d3a,
    30: #3f4945,
    35: #4b5551,
    40: #57605c,
    50: #6f7975,
    60: #89938e,
    70: #a3ada9,
    80: #bfc9c4,
    90: #dbe5e0,
    95: #e9f3ee,
    98: #f2fcf6,
    99: #f5fff9,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

// Define the theme using your custom palette
$ic-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
  ),
  typography: (
    brand-family: "GeneralSans-Regular, sans-serif",
    plain-family: "GeneralSans-Regular, sans-serif",
  ),
  density: (
    scale: -2
  )
));

:root {
  --mdc-dialog-container-shape: 12px;
  --mdc-dialog-container-color: white;
  --mat-dialog-container-max-width: 560px;
  --mat-dialog-container-small-max-width: calc(100vw - 32px) ;
  --mat-dialog-container-min-width: 280px;
  --mat-dialog-content-padding: 2rem;
  --mat-dialog-content-small-padding: 1rem;
  --mat-dialog-actions-padding: 1rem;

  --mdc-text-button-container-shape: 9999px;
  --mdc-filled-button-container-shape: 9999px;
  --mdc-protected-button-container-shape: 9999px;
  --mat-button-container-shape: 9999px;

  --mat-text-button-horizontal-padding: 2rem;
  --mat-filled-button-horizontal-padding: 2rem;
  --mat-protected-button-horizontal-padding: 2rem;
  --mat-button-horizontal-padding: 2rem;

  --mdc-text-button-container-height: 2.5rem;
  --mdc-protected-button-container-height: 2.5rem;
  --mdc-filled-button-container-height: 2.5rem;
  --mdc-outlined-button-container-height: 2.5rem;

  --mdc-filled-button-label-text-color: white;
  --mat-table-background-color: white;
  --mat-paginator-container-background-color: white;
  --mat-select-panel-background-color: white;
  --mat-datepicker-calendar-container-background-color: white;
  --mdc-circular-progress-active-indicator-color: #7d8894;
  --sys-outline-color: #d8d8d8;
  --row-item-outline-color: #d8d8d8;

  @include mat.divider-overrides((
    color: orange,
  ));

  @include mat.form-field-overrides((
    outlined-outline-color: #d8d8d8,
    outlined-hover-outline-color: #7d8894,
  ));
}

html {
  @include mat.all-component-themes($ic-theme);
  --mat-table-row-item-outline-color: #d8d8d8;
  --mat-menu-container-color: white;
  --mdc-outlined-text-field-outline-color: #d8d8d8;

  .mat-mdc-menu-panel {
    box-shadow: 0 2px 4px rgba(0,0,0,0.2) !important;
  }
  .mat-datepicker-popup {
    box-shadow: 0 2px 4px rgba(0,0,0,0.2) !important;
  }
  .mat-mdc-row .mat-mdc-cell, .mat-mdc-header-row .mat-mdc-header-cell {
    border-bottom: 1px solid #d8d8d8;
  }
}

.secondary-progress-spinner {
  @include mat.progress-spinner-color($ic-theme, $color-variant: secondary);
}
.tertiary-progress-spinner {
  @include mat.progress-spinner-color($ic-theme, $color-variant: tertiary);
}
