@use '../utils';

.str-chat {
  /* The border radius used for the borders of the component */
  --str-chat__notification-border-radius: var(--str-chat__border-radius-sm);

  /* The text/icon color of the component */
  --str-chat__notification-color: var(--str-chat__opaque-surface-text-color);

  /* The background color of the component */
  --str-chat__notification-background-color: var(--str-chat__opaque-surface-background-color);

  /* Top border of the component */
  --str-chat__notification-border-block-start: none;

  /* Bottom border of the component */
  --str-chat__notification-border-block-end: none;

  /* Left (right in RTL layout) border of the component */
  --str-chat__notification-border-inline-start: none;

  /* Right (left in RTL layout) border of the component */
  --str-chat__notification-border-inline-end: none;

  /* Box shadow applied to the component */
  --str-chat__notification-box-shadow: 0 0 8px var(--str-chat__box-shadow-color);
}

.str-chat__notification {
  @include utils.component-layer-overrides('notification');
  font: var(--str-chat__subtitle-text);
}
