@use '../utils';

.str-chat {
  /* The border radius used for the borders of the component */
  --str-chat__avatar-border-radius: var(--str-chat__border-radius-circle);

  /* The text/icon color of the component */
  --str-chat__avatar-color: var(--str-chat__on-primary-color);

  /* The background color of the component */
  --str-chat__avatar-background-color: var(--str-chat__primary-color);

  /* Top border of the component */
  --str-chat__avatar-border-block-start: none;

  /* Bottom border of the component */
  --str-chat__avatar-border-block-end: none;

  /* Left (right in RTL layout) border of the component */
  --str-chat__avatar-border-inline-start: none;

  /* Right (left in RTL layout) border of the component */
  --str-chat__avatar-border-inline-end: none;

  /* Box shadow applied to the component */
  --str-chat__avatar-box-shadow: none;

  /* The color of the online indicator (only available in Angular SDK) */
  --str-chat__avatar-online-indicator-color: var(--str-chat__info-color);

  /* The border radius of the online indicator (only available in Angular SDK) */
  --str-chat__avatar-online-indicator-border-radius: var(--str-chat__border-radius-circle);
}

.str-chat__avatar {
  .str-chat__avatar-image {
    @include utils.component-layer-overrides('avatar');
    display: block;
  }

  &.str-chat__avatar--no-letters {
    --str-chat-icon-color: var(--str-chat__avatar-color);
    @include utils.component-layer-overrides('avatar');
  }

  .str-chat__avatar-fallback {
    @include utils.component-layer-overrides('avatar');
    text-transform: uppercase;
  }

  .str-chat__avatar--online-indicator {
    background-color: var(--str-chat__avatar-online-indicator-color);
    border-radius: var(--str-chat__avatar-online-indicator-border-radius);
  }
}

.str-chat__loading-channels-avatar {
  border-radius: var(--str-chat__avatar-border-radius);
}


.str-chat__avatar-group {
  border-radius: var(--str-chat__avatar-border-radius);
}