@use '../utils';

.str-chat {
  /* The border radius used for the borders of the component */
  --str-chat__message-list-border-radius: 0;

  /* The text/icon color of the component */
  --str-chat__message-list-color: var(--str-chat__text-color);

  /* The background color of the component */
  --str-chat__message-list-background-color: var(--str-chat__background-color);

  /* Box shadow applied to the component */
  --str-chat__message-list-box-shadow: none;

  /* Top border of the component */
  --str-chat__message-list-border-block-start: none;

  /* Bottom border of the component */
  --str-chat__message-list-border-block-end: none;

  /* Left (right in RTL layout) border of the component */
  --str-chat__message-list-border-inline-start: none;

  /* Right (left in RTL layout) border of the component */
  --str-chat__message-list-border-inline-end: none;

  /* The border radius used for the borders of the jump to latest message button */
  --str-chat__jump-to-latest-message-border-radius: var(--str-chat__circle-fab-border-radius);

  /* The text/icon color of the jump to latest message button */
  --str-chat__jump-to-latest-message-color: var(--str-chat__circle-fab-color);

  /* The background color of the jump to latest message button */
  --str-chat__jump-to-latest-message-background-color: var(--str-chat__circle-fab-background-color);

  /* The background color of the jump to latest message button in pressed state */
  --str-chat__jump-to-latest-message-pressed-background-color: var(
    --str-chat__circle-fab-pressed-background-color
  );

  /* Box shadow applied to the jump to latest message button */
  --str-chat__jump-to-latest-message-box-shadow: var(--str-chat__circle-fab-box-shadow);

  /* Top border of the jump to latest message button */
  --str-chat__jump-to-latest-message-border-block-start: var(
    --str-chat__circle-fab-border-block-start
  );

  /* Bottom border of the jump to latest message button */
  --str-chat__jump-to-latest-message-border-block-end: var(--str-chat__circle-fab-border-block-end);

  /* Left (right in RTL layout) border of the jump to latest message button */
  --str-chat__jump-to-latest-message-border-inline-start: var(
    --str-chat__circle-fab-border-inline-start
  );

  /* Right (left in RTL layout) border of the jump to latest message button */
  --str-chat__jump-to-latest-message-border-inline-end: var(
    --str-chat__circle-fab-border-inline-end
  );

  /* The background color of the unread messages count on the jump to latest message button */
  --str-chat__jump-to-latest-message-unread-count-background-color: var(
    --str-chat__jump-to-latest-message-color
  );

  /* The text/icon color of the unread messages count on the jump to latest message button */
  --str-chat__jump-to-latest-message-unread-count-color: var(
    --str-chat__jump-to-latest-message-background-color
  );

  /* The color used for displaying thread replies and thread separator at the start of a thread */
  --str-chat__thread-head-start-color: var(--str-chat__text-low-emphasis-color);

  /* The color used for the separator below the first message in a thread */
  --str-chat__thread-head-start-border-block-end-color: var(--str-chat__surface-color);
}

.str-chat__list {
  @include utils.component-layer-overrides('message-list');

  .str-chat__parent-message-li {
    border-block-end: 1px solid var(--str-chat__thread-head-start-border-block-end-color);

    .str-chat__thread-start {
      color: var(--str-chat__thread-head-start-color);
      font: var(--str-chat__subtitle-text);
    }
  }
}

.str-chat__jump-to-latest-message {
  --str-chat-icon-color: var(--str-chat__jump-to-latest-message-unread-count-background-color);

  .str-chat__circle-fab {
    @include utils.component-layer-overrides('jump-to-latest-message');
    @include utils.circle-fab-overrides('jump-to-latest-message');

    .str-chat__jump-to-latest-unread-count {
      background-color: var(--str-chat__jump-to-latest-message-unread-count-background-color);
      color: var(--str-chat__jump-to-latest-message-unread-count-color);
      border-radius: var(--str-chat__jump-to-latest-message-border-radius);
      font: var(--str-chat__caption-text);
    }
  }
}
