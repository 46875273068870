/* Only available in React SDK. */
.str-chat {
  --str-chat-unread-count-badge-color: var(--str-chat__on-unread-badge-color);
  --str-chat-unread-count-badge-background-color: var(--str-chat__danger-color);
}

.str-chat__unread-count-badge {
  background-color: var(--str-chat-unread-count-badge-background-color);
  color: var(--str-chat-unread-count-badge-color);
  border-radius: 9999px;
}
