/* Only available in React SDK. */
.str-chat {
  --str-chat-thread-list-border-color: var(--str-chat__surface-color);
  --str-chat-thread-list-container-background-color: var(--str-chat__secondary-background-color);

  --str-chat-unseen-threads-banner-background-color: var(--str-chat__text-color);
  --str-chat-unseen-threads-banner-color: var(--str-chat__opaque-surface-text-color);

  --str-chat-thread-list-item-background-color-default: var(--str-chat__secondary-background-color);
  --str-chat-thread-list-item-background-color-selected: var(--str-chat__surface-color);

  --str-chat-thread-list-item-text-color: var(--str-chat__text-color);
  --str-chat-thread-list-item-text-low-emphasis-color: var(--str-chat__text-low-emphasis-color);

  --str-chat-thread-list-empty-placeholder-background-color: var(
    --str-chat__secondary-background-color
  );
  --str-chat-thread-list-empty-placeholder-color: var(--str-chat__text-low-emphasis-color);
}

.str-chat__thread-list-container {
  background-color: var(--str-chat-thread-list-container-background-color);
}

.str-chat__unseen-threads-banner {
  border-radius: 16px;
  background: var(--str-chat-unseen-threads-banner-background-color);
  color: var(--str-chat-unseen-threads-banner-color);

  &__button {
    --str-chat-icon-color: var(--str-chat-unseen-threads-banner-color);
  }
}

.str-chat__thread-list {
  
  border-right: 1px solid var(--str-chat-thread-list-border-color);
  
  .str-chat__thread-list-item {
    --str-chat-icon-color: var(--str-chat-thread-list-item-text-color);
    
    border: none;
    background-color: var(--str-chat-thread-list-item-background-color-default);
    color: var(--str-chat-thread-list-item-text-color);

    &[aria-selected='true'] {
      background-color: var(--str-chat-thread-list-item-background-color-selected);

      .str-chat__thread-list-item__parent-message-text,
      .str-chat__thread-list-item__latest-reply-text-and-timestamp {
        color: inherit;
      }
    }

    .str-chat__thread-list-item__parent-message-text {
      color: var(--str-chat-thread-list-item-text-low-emphasis-color);
    }

    .str-chat__thread-list-item__latest-reply {
      --str-chat__avatar-background-color: var(--str-chat__secondary-surface-color);
      --str-chat__avatar-color: var(--str-chat__text-low-emphasis-color);
    }

    .str-chat__thread-list-item__latest-reply-text-and-timestamp {
      color: var(--str-chat-thread-list-item-text-low-emphasis-color);
    }
  }
}

.str-chat__thread-list-empty-placeholder {
  --str-chat-icon-color: var(--str-chat-thread-list-empty-placeholder-color);

  background-color: var(--str-chat-thread-list-empty-placeholder-background-color);
  color: var(--str-chat-thread-list-empty-placeholder-color);
}
