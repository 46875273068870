.str-chat {
  --str-chat__blue950: #001333;
  --str-chat__blue900: #00163d;
  --str-chat__blue800: #002666;
  --str-chat__blue700: #003999;
  --str-chat__blue600: #004ccc;
  --str-chat__blue500: #005fff;
  --str-chat__blue400: #337eff;
  --str-chat__blue300: #669fff;
  --str-chat__blue200: #ccdfff;
  --str-chat__blue100: #e0f0ff;
  --str-chat__blue50: #ebf5ff;

  --str-chat__grey950: #080707;
  --str-chat__grey900: #17191c;
  --str-chat__grey800: #1c1e22;
  --str-chat__grey700: #272a30;
  --str-chat__grey600: #4c525c;
  --str-chat__grey500: #72767e;
  --str-chat__grey400: #b4b7bb;
  --str-chat__grey300: #dbdde1;
  --str-chat__grey200: #e9eaed;
  --str-chat__grey100: #f4f4f5;
  --str-chat__grey50: #ffffff;

  --str-chat__red900: #330003;
  --str-chat__red800: #660006;
  --str-chat__red700: #990008;
  --str-chat__red600: #cc000b;
  --str-chat__red500: #ff000e;
  --str-chat__red400: #ff3742;
  --str-chat__red300: #ff666e;
  --str-chat__red200: #ff999f;
  --str-chat__red100: #ffe5e7;

  --str-chat__green900: #062d16;
  --str-chat__green800: #0d592c;
  --str-chat__green700: #138643;
  --str-chat__green600: #19b359;
  --str-chat__green500: #20e070;
  --str-chat__green400: #4ce68c;
  --str-chat__green300: #79eca9;
  --str-chat__green200: #a6f2c6;
  --str-chat__green100: #e9f1ff;

  --str-chat__yellow900: #332500;
  --str-chat__yellow800: #664900;
  --str-chat__yellow700: #996e00;
  --str-chat__yellow600: #cc9200;
  --str-chat__yellow500: #ffb700;
  --str-chat__yellow400: #ffd466;
  --str-chat__yellow300: #ffe299;
  --str-chat__yellow200: #fff1cc;
  --str-chat__yellow100: #fff8e5;
}
