$cc-white: #ffffff;
$cc-gray-ultralight: #f5f4f4;
$cc-gray-light: #ebeaea;
$cc-gray: #d8d8d8;
$cc-gray-blue: #7d8894;
$cc-black: #373736;
$background-primary: #fcfbfb;
$background-auth: #f5f4f4;
$cc-green-primary: #4cc2ac;
$cc-green-primary-dark: #3aa18e;
$cc-green-light: #ddf4ef;
$cc-green: #4cc2ac;
$cc-green-dark: #027a64;
$cc-green-darkest: #255147;
$cc-yellow: #ffda6f;
$cc-border-gray: #d8d8d8;
$cc-muted-text: #5d6872;
$cc-lightgray: rgb(245, 244, 244);
$cc-orange-light: #edb2a4;

$cc-green-new: #4cc2ac;
$cc-green-over: hsl(167, 49%, 48%);
$cc-green-low-over: hsl(167, 51%, 95%);
$cc-red: hsl(349, 89%, 65%);
$cc-red-dark: hsl(0, 88%, 61%);
$cc-red-low: hsl(0, 100%, 95%);

$colorV2-beige: #f0e9ce;

$primary: $cc-green-primary;
$primary-invert: $cc-white;
$size-1: 68px;
$size-2: 50px;
$size-3: 23px;
$size-4: 18px;
$size-5: 16px;
$size-6: 14px;
$size-7: 12px;
$size-30px: 30px;
$border-radius: 10px;
$breakpoint-mobile: 480px;
$breakpoint-tablet-max: 1023px;
$breakpoint-tablet-min: 1024px;
$danger: rgb(243, 70, 70);

$bp-mobile: 768px; // to
$bp-tablet: 769px; //from
$bp-desktop: 1024px; //from
$bp-widescreen: 1216px; //from
$bp-fullhd: 1408px; //from
