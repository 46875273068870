@use '../utils';

/* Only available in React SDK. */
.str-chat {
  /* The border radius used for the borders of the component */
  --str-chat__virtual-list-border-radius: 0;

  /* The text/icon color of the component */
  --str-chat__virtual-list-color: var(--str-chat__text-color);

  /* The background color of the component */
  --str-chat__virtual-list-background-color: var(--str-chat__background-color);

  /* Box shadow applied to the component */
  --str-chat__virtual-list-box-shadow: none;

  /* Top border of the component */
  --str-chat__virtual-list-border-block-start: none;

  /* Bottom border of the component */
  --str-chat__virtual-list-border-block-end: none;

  /* Left (right in RTL layout) border of the component */
  --str-chat__virtual-list-border-inline-start: none;

  /* Right (left in RTL layout) border of the component */
  --str-chat__virtual-list-border-inline-end: none;
}

.str-chat__virtual-list {
  @include utils.component-layer-overrides('virtual-list');

  .str-chat__parent-message-li {
    border-block-end: 1px solid var(--str-chat__thread-head-start-border-block-end-color);

    .str-chat__thread-start {
      color: var(--str-chat__thread-head-start-color);
      font: var(--str-chat__subtitle-text);
    }
  }
}
