$calendar-border: .1rem solid $white-ter !default
$calendar-border-radius: $radius-small !default
$calendar-selection-background-color: $primary !default
$calendar-days-background-color: transparent !default
$calendar-selection-days-color: $grey-light !default
$calendar-date-color: $text !default
$calendar-date-hover-background-color: $white !default
$calendar-date-hover-border: .1rem solid $primary !default
$calendar-date-hover-border: $primary !default
$calendar-today-background: transparent !default
$calendar-today-border: .1rem dotted $primary !default
$calendar-today-color: $primary !default
$calendar-range-background-color: $primary !default
$calendar-body-padding: 0 !default
$calendar-selection-padding: .3rem 0 !default
$calendar-selection-nav-padding: .5em !default
$calendar-date-padding: .1rem 0 !default

@import timepicker

=datetimepicker-header
	.datetimepicker-header
		display: flex
		flex-direction: column
		flex: 1
		text-align: left
		position: relative
		padding: .5rem 1rem
		font-size: 1rem
		box-shadow: 0 .2rem .2rem -.2rem rgba(10,10,10,.1)
		&.is-date-only
			flex-direction: row
			.datetimepicker-selection-end
				.datetimepicker-selection-wrapper
					justify-content: flex-end !important
		.datetimepicker-selection-details
			flex: 1
		.datetimepicker-selection-from,
		.datetimepicker-selection-to
			font-size: .65rem
			color: $grey-light
			text-transform: uppercase
			padding: 0
		.datetimepicker-selection-start,
		.datetimepicker-selection-end
			padding: 0
			display: flex
			flex: 1
			flex-direction: row
			.datetimepicker-selection-wrapper
				flex-direction: row
				display: flex
				min-height: 2.5rem
				flex: 1 1 2rem
			.datetimepicker-selection-day
				flex: 0
				text-transform: capitalize
				font-size: 2rem
				line-height: 2.5rem
				margin-right: .5rem
				color: $primary
				font-weight: 100
			.datetimepicker-selection-date
				font-size: .85rem
				line-height: .85rem
				justify-content: center
				display: flex
				flex-direction: column
				font-weight: 300
			.datetimepicker-selection-month
				color: $text
				text-transform: capitalize
			.datetimepicker-selection-weekday
					font-size: .75em
					color: #b6b4b6
					text-transform: capitalize
					text-align: left
			.datetimepicker-selection-hour
				font-size: 1.5rem
				font-weight: 100
				flex: 1
				justify-content: center
				align-items: center
				text-align: center
		.datetimepicker-selection-start,
		.datetimepicker-selection-end
			align-items: center
			&.is-centered
				.datetimepicker-selection-wrapper
					justify-content: center
					align-items: center
		.datetimepicker-selection-time
			flex: 1
			display: flex
			.datetimepicker-selection-time-icon
				display: flex
				justify-content: center
				align-items: center
				padding: 0 1rem
				color: $grey-light
			

=datepicker-nav
	.datepicker-nav
		background: $calendar-selection-background-color
		color: findColorInvert($calendar-selection-background-color)
		display: flex
		align-items: center
		justify-content: space-between
		padding: $calendar-selection-nav-padding
		flex-direction: row
		.datepicker-nav-month-year
			flex-direction: row
			display: flex
		.datepicker-nav-month,
		.datepicker-nav-year
			text-decoration: none
			font-size: $size-6
			color: findColorInvert($primary)
			&:hover
				cursor: pointer
		.datepicker-nav-month
			text-transform: capitalize
		.datepicker-nav-year
			font-weight: $weight-bold
		.datepicker-nav-previous, .datepicker-nav-next
			flex-basis: auto
			flex-grow: 0
			flex-shrink: 0
			text-decoration: none
			color: $white
			&:hover
				background-color: transparent
				svg
					stroke-width: 1em
			svg
				stroke: currentColor
				width: 11.25px
				height: .75rem

=datepicker-body
	box-shadow: 0 2px 3px rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.1)
	// .datepicker
	//   box-shadow: 0 .2rem .2rem -.2rem rgba(10,10,10,.1)
	.datepicker-body
		overflow: hidden
		.datepicker-dates
			display: none
			&.is-active
				display: flex
				flex-direction: column
		.datepicker-months,
		.datepicker-years
			display: none
			&.is-active
				display: flex
				flex-direction: row
		.datepicker-weekdays
				display: flex
				flex-wrap: wrap
				justify-content: space-around
				padding: $calendar-selection-padding
				background: $calendar-days-background-color
				color: findColorInvert($calendar-days-background-color)
				font-size: $size-7
				.datepicker-date
					background: $calendar-days-background-color !important
					color: $calendar-selection-days-color
					padding: .3rem
		.datepicker-dates
			.datepicker-days
					display: flex
					flex-direction: row
					flex-wrap: wrap
					justify-content: space-around
					padding: $calendar-body-padding
					color: $grey
					.datepicker-date
						background: #f5f5f5
						display: flex
						justify-content: center
						flex: 14.28571%
						margin: .1rem 0
						align-items: center
						.date-item
							margin: 0 auto
							appearance: none
							background: transparent
							border: none
							border-radius: 25091983px
							color: $calendar-date-color
							cursor: pointer
							height: 2.2rem
							outline: none
							padding: 0
							position: relative
							text-align: center
							text-decoration: none
							transition: all .2s ease
							vertical-align: middle
							white-space: nowrap
							width: 2.2rem
							// transform: scale(0.7)
							animation: bounce-button-in 0.45s 0s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
							&.is-today
								background: $calendar-today-background
								border: $calendar-today-border
								color: $calendar-today-color
							&:focus
								background: $calendar-date-hover-background-color
								border-color: $calendar-date-hover-background-color
								color: findColorInvert($calendar-date-hover-background-color)
								text-decoration: none
							&.is-active
								background: $primary
								border-color: $primary
								border-radius: 25091983px
								color: findColorInvert($primary)
								&.is-today
									border-color: findColorInvert($primary) !important
									color: findColorInvert($primary) !important
							&:hover
								background: $calendar-date-hover-background-color
								border: $calendar-date-hover-border
								color: findColorInvert( $calendar-date-hover-background-color )
								text-decoration: none
						&.is-current-month
							background: transparent
						&.is-disabled,
						&[disabled]
							background-color: $white-ter
							background-image: url(data:image/gif;base64,R0lGODlhBQAFAOMAAP/14////93uHt3uHt3uHt3uHv///////////wAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAAAALAAAAAAFAAUAAAQL0ACAzpG0YnonNxEAOw==) !important
							.date-item,
							.calendar-event
								cursor: default
								opacity: .25
								pointer-events: none
						&.datepicker-range
							position: relative
							background: $calendar-range-background-color
							z-index: 1
							&.datepicker-range-start,
							&.datepicker-range-end
								.date-item
									background: findColorInvert($primary)
									border: .1rem solid $primary
									color: $primary
									box-shadow: 0 .2rem .2rem rgba(10,10,10,.1)
							&.datepicker-range-start
								border-top-left-radius: 25091983px
								border-bottom-left-radius: 25091983px
							&.datepicker-range-end
								border-top-right-radius: 25091983px
								border-bottom-right-radius: 25091983px
							.date-item
								background-color: $primary
								color: findColorInvert($primary)
								&.is-today
									border-color: findColorInvert($primary) !important
									color: findColorInvert($primary) !important
		.datepicker-months,
		.datepicker-years
			display: none
			flex-direction: row
			flex-wrap: wrap
			padding: .5rem
			max-height: 17rem
			overflow-y: auto
			&.is-active
				display: flex
			.datepicker-month,
			.datepicker-year
				padding: .5rem
				margin: .5rem
				font-size: $size-7
				text-align: center
				flex: 1 25%
				text-transform: capitalize
				border-radius: 25091983px
				border: .1rem solid transparent
				&.is-active
					background: $primary
					border-color: $primary
					color: findColorInvert( $primary )
				&:hover
					cursor: pointer
					background: $calendar-date-hover-background-color
					border: $calendar-date-hover-border
					color: findColorInvert( $calendar-date-hover-background-color )

=datetimepicker-footer
	.datetimepicker-footer
		display: flex
		flex: 1
		justify-content: space-evenly
		margin: 0
		> *
			text-decoration: none !important

=datetimepicker
	background: $white
	border-radius: $calendar-border-radius
	max-width: 320px
	z-index: 1
	+datetimepicker-header
	+datepicker-nav
	+datepicker-body
	+datetimepicker-footer
	&.is-datetimepicker-default
		.datetimepicker-container
			display: flex
			flex-direction: column
			
			&:after, &:before
				bottom: 100%
				left: 15%
				border: solid transparent
				content: " "
				height: 0
				width: 0
				position: absolute
				pointer-events: none
			&:after
				border-color: rgba(255, 255, 255, 0)
				border-bottom-color: #ffffff
				border-width: .75rem
				margin-left: -.75rem
			&:before
				border-color: rgba(74, 74, 74, 0)
				border-bottom-color: #4a4a4a
				border-width: .75rem
				margin-left: -.75rem
			&.has-header-bottom
				flex-direction: column-reverse
			

	&.is-large
		max-width: 100%
		.datepicker-body
			.datepicker-date
				border-bottom: $calendar-border
				border-right: $calendar-border
				display: flex
				flex-direction: column
				height: 11rem
				padding: 0
				&:nth-child(7n)
					border-right: 0
				&:nth-last-child(-n+7)
					border-bottom: 0
		.date-item
			align-self: flex-end
			height: 2.2rem
			margin-right: .5rem
			margin-top: .5rem
		.datepicker-range
			&:before,
			&::before
				top: 1.9rem
			&.datepicker-range-start::before
				left: auto
				width: 1.9rem
			&.datepicker-range-end::before
				right: 1.9rem
		.datepicker-events
			flex-grow: 1
			line-height: 1
			overflow-y: auto
			padding: .5rem
		.datepicker-event
			background-color: $grey
			border-radius: $radius-small
			color: $white
			display: block
			font-size: 1rem
			margin: .2rem auto
			overflow: hidden
			padding: .3rem .4rem
			text-align: left
			text-overflow: ellipsis
			vertical-align: baseline
			white-space: nowrap
			@each $name, $pair in $colors
				$color: nth($pair, 1)
				$color-invert: nth($pair, 2)
				&.is-#{$name}
					background-color: $color
					color: $color-invert

.datetimepicker
	transition: width .8s cubic-bezier(1,-.55,.2,1.37),opacity .2s ease,visibility .2s ease,margin .2s ease
	display: none
	&.is-active
		display: block
	+datetimepicker
	+timepicker

	@each $name, $pair in $colors
		$color: nth($pair, 1)
		$color-invert: nth($pair, 2)
		&.is-#{$name}
			.datetimepicker-header
				.datetimepicker-selection-day
					color: $color
			.datepicker-nav
				background: $color
				color: $color-invert
			.datepicker-body
				.datepicker-dates
					.datepicker-days
						.datepicker-date
							.date-item
								&.is-active
									background: $color
									border-color: $color
								&.is-today
									border-color: $color
									color: $color
								&:hover
									border-color: $color
							&.datepicker-range
								background: $color
								&:before,
								&::before
									background: $color
								.date-item
									background-color: $color
									color: $color-invert
									&.is-today
										border-color: $color-invert !important
										color: $color-invert !important
								&.datepicker-range-start,
								&.datepicker-range-end
									.date-item
										background: $color-invert
										border-color: $color
										color: $color
	@each $name, $value in $shades
		$color: $value
		$color-invert: findColorInvert($color)
		&.is-#{$name}
			.datetimepicker-header
				.datetimepicker-selection-day
					color: $color
			.datepicker-nav
				background: $color
				color: $color-invert
			.datepicker-body
				.datepicker-dates
					.datepicker-days
						.datepicker-date
							.date-item
								&.is-active
									background: $color
									border-color: $color
								&.is-today
									border-color: $color
									color: $color
								&:hover
									border-color: $color
							&.datepicker-range
								background: $color
								&:before,
								&::before
									background: $color
								.date-item
									background-color: $color
									color: $color-invert
									&.is-today
										border-color: $color-invert !important
										color: $color-invert !important
								&.datepicker-range-start,
								&.datepicker-range-end
									.date-item
										background: $color-invert
										border-color: $color
										color: $color

.datetimepicker-dummy
	position: relative
	display: flex
	width: 100%
	height: 2.5rem
	&:before,
	&::before
		content: ''
		mask: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNjAgNjAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwYXRoIGZpbGw9ImN1cnJlbnRjb2xvciIgZD0iTTU3LDRoLTdWMWMwLTAuNTUzLTAuNDQ3LTEtMS0xaC03Yy0wLjU1MywwLTEsMC40NDctMSwxdjNIMTlWMWMwLTAuNTUzLTAuNDQ3LTEtMS0xaC03Yy0wLjU1MywwLTEsMC40NDctMSwxdjNIM0MyLjQ0Nyw0LDIsNC40NDcsMiw1djExdjQzYzAsMC41NTMsMC40NDcsMSwxLDFoNTRjMC41NTMsMCwxLTAuNDQ3LDEtMVYxNlY1QzU4LDQuNDQ3LDU3LjU1Myw0LDU3LDR6IE00MywyaDV2M3YzaC01VjVWMnogTTEyLDJoNXYzdjNoLTVWNVYyeiBNNCw2aDZ2M2MwLDAuNTUzLDAuNDQ3LDEsMSwxaDdjMC41NTMsMCwxLTAuNDQ3LDEtMVY2aDIydjNjMCwwLjU1MywwLjQ0NywxLDEsMWg3YzAuNTUzLDAsMS0wLjQ0NywxLTFWNmg2djlINFY2ek00LDU4VjE3aDUydjQxSDR6Ii8+PHBhdGggZmlsbD0iY3VycmVudGNvbG9yIiBkPSJNMzgsMjNoLTdoLTJoLTdoLTJoLTl2OXYydjd2MnY5aDloMmg3aDJoN2gyaDl2LTl2LTJ2LTd2LTJ2LTloLTlIMzh6TTMxLDI1aDd2N2gtN1YyNXpNMzgsNDFoLTd2LTdoN1Y0MXpNMjIsMzRoN3Y3aC03VjM0ek0yMiwyNWg3djdoLTdWMjV6TTEzLDI1aDd2N2gtN1YyNXpNMTMsMzRoN3Y3aC03VjM0ek0yMCw1MGgtN3YtN2g3VjUwek0yOSw1MGgtN3YtN2g3VjUweiBNMzgsNTBoLTd2LTdoN1Y1MHogTTQ3LDUwaC03di03aDdWNTB6IE00Nyw0MWgtN3YtN2g3VjQxek00NywyNXY3aC03di03SDQ3eiIvPjwvZz48L3N2Zz4=)
		position: absolute
		top: .65rem
		left: .75rem
		width: 1.25rem
		height: 1.25rem
		background-color: $primary
	.datetimepicker-clear-button
		background: transparent
		border: 0
		color: $primary
		cursor: pointer
		font-weight: $weight-normal
		outline: 0
		transform: rotate(45deg)
		font-size: 1.5rem
		height: 1.75rem
		margin: .4rem .2rem 0 0
		padding: 0
		position: absolute
		right: 0
		top: 0
		width: 1.75rem
	.datetimepicker-dummy-wrapper
		border: 1px solid $grey-light
		cursor: pointer
		display: flex
		flex: 1 100%
		flex-wrap: nowrap
		height: 100%
		max-width: 100%
		.datetimepicker-dummy-input
			color: $text
			font-size: 1rem
			text-align: left
			text-indent: 1rem
			flex: 1
			height: 100%
			max-width: 50%
			&:first-child
				text-indent: 2.5rem
			&.is-datetimepicker-range
				background: transparent url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjE4IiB2aWV3Qm94PSIwIDAgOCAxOCI+CiAgICA8cGF0aCBmaWxsPSIjOTU5OUFBIiBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik0uMTE5LjcxOGw3LjE1OCA3LjQwNy0uMDMzLS41NTEtNi43MzcgOC44ODlhLjQyNS40MjUgMCAwIDAgLjA4LjU5My40Mi40MiAwIDAgMCAuNTktLjA4bDYuNzM3LTguODg5YS40MjUuNDI1IDAgMCAwLS4wMzMtLjU1MUwuNzIzLjEyOEEuNDIuNDIgMCAwIDAgLjEyOC4xMmEuNDI1LjQyNSAwIDAgMC0uMDA5LjU5OHoiLz4KPC9zdmc+Cg==) no-repeat 100%/8px
		input
			background: transparent
			outline: none
			word-spacing: .5rem
			border: 0

.datetimepicker-dummy
	@each $name, $pair in $colors
		$color: nth($pair, 1)
		$color-invert: findColorInvert($color)
		&.is-#{$name}
			&:before,
			&::before
				background-color: $color
			.datetimepicker-clear-button
				color: $color
	@each $name, $value in $shades
		$color: $value
		$color-invert: findColorInvert($color)
		&.is-#{$name}
			&:before,
			&::before
				background-color: $color
			.datetimepicker-clear-button
				color: $color

.modal
	.datetimepicker
		display: block
		max-height: 95vh
		width: 22rem
		max-width: 95vw
		overflow-y: auto