@use '../utils';

.str-chat {
  /* The border radius used for the borders of the component */
  --str-chat__tooltip-border-radius: var(--str-chat__border-radius-xs);

  /* The text/icon color of the component */
  --str-chat__tooltip-color: var(--str-chat__text-color);

  /* The background color of the component */
  --str-chat__tooltip-background-color: var(--str-chat__secondary-background-color);

  /* Top border of the component */
  --str-chat__tooltip-border-block-start: none;

  /* Bottom border of the component */
  --str-chat__tooltip-border-block-end: none;

  /* Left (right in RTL layout) border of the component */
  --str-chat__tooltip-border-inline-start: none;

  /* Right (left in RTL layout) border of the component */
  --str-chat__tooltip-border-inline-end: none;

  /* Box shadow applied to the component */
  --str-chat__tooltip-box-shadow: 0 0 20px var(--str-chat__box-shadow-color);
}

.str-chat__tooltip {
  @include utils.component-layer-overrides('tooltip');
  font: var(--str-chat__caption-text);

  &::after {
    background-color: var(--str-chat__tooltip-background-color);
  }
}
