@use "@angular/material" as mat;
@import "./assets/pallete.scss";
// Override Bulma font weights, as our fonts don't look great too heavy
$weight-bold: 600 !default;
$weight-semibold: 500 !default;
@import "../node_modules/bulma/bulma.sass";
@import "../node_modules/bulma-extensions/bulma-divider/src/sass/index.sass";
@import "../node_modules/bulma-extensions/bulma-steps/src/sass/index.sass";
@import "../node_modules/bulma-extensions/bulma-calendar/src/sass/index.sass";
@import "../node_modules/bulma-extensions/bulma-pageloader/src/sass/index.sass";
@import "../node_modules/bulma-extensions/bulma-tooltip/src/sass/index.sass";
@import "./assets/popover.scss";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "./assets/calendar.scss";
@import "./assets/mixins.scss";
@import "../node_modules/@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "./assets/m3-theme.scss";
@import "stream-chat-angular/src/assets/styles/scss/index.scss";

html,
body.ic {
  height: 100%;
  color: $cc-black;
  padding: 3em 0 0;

  @media only screen and (max-width: $breakpoint-tablet-max) {
    padding-top: 52px;
  }

  margin: 0;
  background-color: #f5f4f4 !important;
  @include regularFont();
}

body,
button,
input,
select,
textarea {
  font-family: GeneralSans, Helvetica, sans-serif !important;
}

a {
  color: $cc-green-primary-dark;
  &:hover {
    color: $cc-green-primary;
  }
}

.strong {
  @include legacySemiBold();
}

//tooltip position
.cdk-overlay-container {
  z-index: 10000;
}

.min-height-container {
  min-height: 700px;
  padding: 100px 0;
}

.container-fluid-limited {
  max-width: 1440px;
  margin: 0 auto;
}

.section {
  padding-top: 1rem !important;
}

.ic-svg {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &.pencil-folder {
    background-image: url("assets/images/svg/pencil_folder.svg");
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }
}

.icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &.icon-dashboard {
    background-image: url("assets/images/svg/dashboard.svg");
  }

  &.icon-messages {
    background-image: url("assets/images/svg/messages.svg");
  }
  &.icon-messages-primary {
    background-image: url("assets/images/svg/messages-primary.svg");
  }

  &.icon-waiting_room {
    background-image: url("assets/images/svg/waiting-room.svg");
  }

  &.icon-file {
    background-image: url("assets/images/svg/file.svg");
  }

  &.icon-notifications {
    background-image: url("assets/images/svg/notifications.svg");
  }

  &.icon-tag {
    background-image: url("assets/images/svg/tag.svg");
  }

  &.icon-location {
    background-image: url("assets/images/svg/location.svg");
  }

  &.icon-edit-profile {
    background-image: url("assets/images/svg/edit_profile.svg");
  }

  &.icon-edit {
    background-image: url("assets/images/svg/edit.svg");
  }

  &.icon-edit-dark {
    background-image: url("assets/images/svg/edit_dark.svg");
  }

  &.icon-edit-primary {
    background-image: url("assets/images/svg/edit_primary.svg");
  }
  &.icon-favorite {
    background-image: url("assets/images/svg/favorite.svg");
  }

  &.icon-invite-client {
    background-image: url("assets/images/svg/invite_clients.svg");
  }
  &.icon-invite-client-primary {
    background-image: url("assets/images/svg/invite_clients-primary.svg");
  }
  &.icon-graph-up-arrow {
    background-image: url("assets/images/svg/graph-up-arrow.svg");
  }
  &.icon-feedback {
    background-image: url("assets/images/svg/feedback.svg");
  }

  &.icon-invoice {
    background-image: url("assets/images/svg/invoice.svg");
  }

  &.icon-referral-program {
    background-image: url("assets/images/svg/referral_program.svg");
  }

  &.icon-my-clients {
    background-image: url("assets/images/svg/my_clients.svg");
  }

  &.icon-smiley {
    background-image: url("assets/images/svg/smiley.svg");
  }

  &.icon-community {
    background-image: url("assets/images/svg/community.svg");
  }

  &.icon-logout {
    background-image: url("assets/images/svg/log_out.svg");
  }

  &.icon-journal {
    background-image: url("assets/images/svg/journal.svg");
  }

  &.icon-statistics {
    background-image: url("assets/images/svg/statistics.svg");
  }

  &.icon-settings {
    background-image: url("assets/images/svg/settings.svg");
  }

  &.icon-settings-primary {
    background-image: url("assets/images/svg/settings-primary.svg");
  }

  &.icon-settings-white {
    background-image: url("assets/images/svg/settings-white.svg");
  }

  &.icon-calendar {
    background-image: url("assets/images/svg/calendar.svg");
  }

  &.icon-calendar-primary {
    background-image: url("assets/images/svg/calendar_primary.svg");
  }

  &.icon-read {
    background-image: url("assets/images/svg/icon-read.svg");
  }

  &.icon-send {
    background-image: url("assets/images/svg/send.svg");
  }

  &.icon-lock {
    background-image: url("assets/images/svg/lock.svg");
  }

  &.icon-attachment {
    background-image: url("assets/images/svg/attachment.svg");
  }

  &.icon-like {
    background-image: url("assets/images/svg/like.svg");
    margin-right: 5px;
  }

  &.icon-trash {
    background-image: url("assets/images/svg/trash.svg");
  }

  &.icon-archive {
    background-image: url("assets/images/svg/archive.svg");
    margin-right: 5px;
  }

  &.icon-search {
    background-image: url("assets/images/svg/search.svg");
  }

  &.icon-check {
    background-image: url("assets/images/svg/check.svg");
  }

  &.icon-check-green {
    background-image: url("assets/images/svg/check-green.svg");
  }

  &.icon-note {
    background-image: url("assets/images/svg/note.svg");
    margin-right: 5px;
  }

  &.icon-note-primary {
    background-image: url("assets/images/svg/note-primary.svg");
  }

  &.icon-subscription {
    background-image: url("assets/images/svg/subscription.svg");
    margin-right: 5px;
  }

  &.icon-share {
    background-image: url("assets/images/svg/share.svg");
    margin-right: 10px;
  }

  &.icon-share-white {
    background-image: url("assets/images/svg/share-white.svg");
    height: 1.35em;
  }

  &.icon-share-gray {
    background-image: url("assets/images/svg/share-gray.svg");
    margin-right: 10px;
  }

  &.icon-upload {
    background-image: url("assets/images/svg/upload.svg");
  }

  &.icon-list {
    background-image: url("assets/images/svg/list.svg");
  }

  &.icon-my-office {
    background-image: url("assets/images/svg/list.svg");
  }

  &.icon-plus {
    background-image: url("assets/images/svg/plus-simple.svg");
  }

  &.icon-video {
    background-image: url("assets/svg/video_add.svg");
    background-size: 100%;
    width: 26px !important;
  }

  &.icon-video-primary {
    background-image: url("assets/images/svg/video-primary.svg");
  }

  &.icon-start-session-primary {
    background-image: url("assets/images/svg/start_session-primary.svg");
  }

  &.icon-chevron-down-green {
    background-image: url("assets/images/svg/chevron-down-green.svg");
  }
  &.icon-chevron-up-green {
    transform: rotate(180deg);
    background-image: url("assets/images/svg/chevron-down-green.svg");
  }
}

.ic {
  .modal-background {
    background: rgba($color: $cc-gray-blue, $alpha: 0.4);
    position: fixed;
    z-index: 50;
  }

  .modal {
    z-index: 9999;

    .modal-card {
      background: #ffffff;
      z-index: 51;
      @include box-shadow();
      @include border-radius();
    }

    .modal-card-content {
      padding: 2em;
    }

    .modal-card-head {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      border-bottom: 0;
      & > div {
        width: 100%;
        display: flex;
      }

      .modal-card-title {
        font-size: 23px;
        color: #373736;
        margin-bottom: 14px;
      }

      .progress-items {
        display: flex;
        width: 100%;
        margin-top: 1rem;

        li {
          font-size: 14px;
          margin-right: 0.5rem;
        }
      }
    }

    .modal-card-foot {
      background: white;
      display: block;
      text-align: right;
      border-top: 1px solid $cc-gray-light;
    }

    .modal-content {
      background: #ffffff;
      z-index: 51;
      padding: 2rem;
      @include box-shadow();
      @include border-radius();

      .modal-title {
        font-size: 23px;
        color: #373736;
        margin-bottom: 1.5rem;
        line-height: 2rem;
      }

      p {
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 1.5rem;
      }

      .modal-actions {
        margin-top: 1rem;
        padding-top: 1rem;
        display: flex;
        justify-content: end;
      }

      .button.is-primary {
        padding-left: 2rem;
        padding-right: 2rem;
        display: flex;
      }

      .button.is-primary:not(.has-margin-left-*) {
        margin-left: auto;
      }
    }

    .delete {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      background: none;
      z-index: 100;
      transition: all 0.25s ease-in-out;

      &::after,
      &:before {
        background: $cc-gray;
      }
      &:hover {
        &::after,
        &:before {
          background: $cc-black;
        }
      }
    }

    &.is-narrow {
      .modal-content {
        max-width: 400px;
      }
    }
  }

  .statistics-title {
    display: flex;
    align-items: center;
  }

  .notification {
    margin: 0 10px 2rem 10px;

    &.is-primary {
      color: #373736;
      background: #ecf8f6;
      border: 2px solid rgba(116, 208, 191, 0.2);

      strong {
        font-size: 18px;
        line-height: 24px;
      }

      .delete {
        background: rgba(116, 208, 191, 0.2);
      }
    }

    &.is-warning {
      background: rgba($color: $cc-yellow, $alpha: 0.4);
      border: 1px solid $cc-yellow;
      text-align: center;
    }

    &.is-danger {
      color: $danger;
      background: rgba($color: $danger, $alpha: 0.2);
      border: 1px solid $danger;
    }
  }
}

.indicators {
  display: flex;
  align-items: center;
  justify-content: center;

  .indicator {
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    margin: 0 0.25rem;
    cursor: pointer;
    border: 2px solid $cc-border-gray;

    &.is-active {
      box-shadow: inset 0 0 0 2px white;
      background: $cc-border-gray;
    }
  }
}

// below styles are so we can use our existing styles with our legacy modals. We should tyr to keep them in sync
.modal-title {
  font-size: 23px;
  color: #373736;
  margin-bottom: 14px;
}

.modal-content {
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: 1rem;
  width: 645px;
  max-width: 95%;
}

.modal-actions {
  margin-top: 1rem;
  padding-top: 1rem;
  display: flex;
  justify-content: end;

  .button {
    margin-left: 1rem;
  }
}

.button.is-primary:not(.is-outlined) {
  background-color: $primary !important;
  @include regularFont();
  &:hover,
  &:active {
    background-color: #6acdbb !important;
  }
}

.button.is-primary.is-focused {
  border-color: #6acdbb !important;
}
.button:active,
.button:focus,
.button.is-focused {
  box-shadow: none !important;
}

.ic {
  .notification {
    margin-bottom: 2rem;

    &.is-primary {
      color: #373736;
      background: #ecf8f6;
      border: 2px solid rgba(116, 208, 191, 0.2);

      strong {
        font-size: 18px;
        line-height: 24px;
      }

      .delete {
        background: rgba(116, 208, 191, 0.2);
      }
    }

    &.is-danger {
      color: $danger;
      background: rgba($color: $danger, $alpha: 0.2);
      border: 1px solid $danger;
    }
  }
}

.indicators {
  display: flex;
  align-items: center;
  justify-content: center;

  .indicator {
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    margin: 0 0.25rem;
    cursor: pointer;
    border: 2px solid $cc-border-gray;

    &.is-active {
      box-shadow: inset 0 0 0 2px white;
      background: $cc-border-gray;
    }
  }
}

.button {
  &.is-ghost {
    border-style: dashed;
  }
}

.menu-list-hr {
  background-color: $cc-gray;
  height: 1px;
}

.category-badge {
  background: #f5f4f4;
  font-size: 16px;
  color: #373736;
  text-align: center;
  display: inline-block;
  padding: 0.25rem 1rem;
  margin: 0 0.5rem 0.5rem 0;
  border-radius: 100px;
}

.notifications-badge {
  background-color: $cc-orange-light;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: 18px;
  width: 18px;
  position: absolute;
  color: white;
  font-size: 10px;
  padding: 3px;
  border: 1px solid white;

  &.header-badge {
    right: 5px;
    top: 0;
  }

  &.header-picture-badge {
    left: 24px;
    top: 0;
  }

  &.sidenav-badge {
    top: 0;
    left: 42px;
  }
}

.dashboard-status-header {
  display: flex;
  align-items: center;
}

.dashboard-teaser {
  margin-bottom: 3rem;

  .dashboard-teaser-header {
    margin-bottom: 1em;

    .button {
      margin-left: 0.5em;
    }
  }

  .dashboard-teaser-title {
    display: inline-flex;
    font-size: 23px;
    @include mediumFont();
    margin: 0 0.25rem 0 0;
  }
}
@media (max-width: $bp-mobile) {
  .dashboard-teaser {
    .dashboard-teaser-header {
      margin-bottom: 0.5em;
    }
    .dashboard-teaser-title {
      font-size: 18px;
    }
  }
}

.ic .card {
  background: $cc-white;
  border: 0;
  @include box-shadow();

  &.no-shadow {
    box-shadow: none;
  }

  &.has-border {
    &::before {
      content: " ";
      display: block;
      position: absolute;
      top: -1px;
      height: 5px;
      left: -1px;
      right: -1px;
      background: $primary;
      z-index: 31;
    }

    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .card {
    box-shadow: none;
    .is-danger {
      color: $danger;
      background: rgba($color: $danger, $alpha: 0.2);
      border: 1px solid $danger;
    }
  }
}

.mobile-navbar {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: left;

  .button {
    margin-right: 0.5rem;
  }
}

// inputs/ forms
.field {
  &:not(:last-child) {
    margin-bottom: 1rem !important;
  }
  .label {
    @include regularFont();
    font-size: 12px;
    color: #88939e;
    letter-spacing: 0;
    line-height: 24px;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  .input:not(.addressinput),
  .textarea {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #ccc;
    padding: 0.25rem 0;
    box-shadow: none;

    &:focus,
    &:active {
      box-shadow: none !important;
      border-color: $primary;
    }
  }

  .button {
    border-radius: $border-radius;
  }
}

.field-auth {
  .label-auth {
    position: absolute;
    top: 10%;
    left: 0;
    height: 16px;
    margin-top: -8px;
    line-height: 16px;
    color: #88939e;
    font-size: 13px;
    transition: 200ms linear all;
    pointer-events: none;
  }
}

// .textarea:focus,
// .textarea:active {
//   border-color: $cc-green-primary !important;
//   box-shadow: 0 0 0 0.125em transparentize($cc-green-primary, 0.8) !important;
// }

// .input:not(.auth_input):focus,
// .input:not(.auth_input):active {
//   border-color: $cc-green-primary !important;
//   box-shadow: 0 0 0 0.125em transparentize($cc-green-primary, 0.8) !important;
// }

.select:focus,
.select:active,
.select select:focus,
.select select:active,
.input:focus,
.input:active {
  border-color: $cc-green-primary !important;
  box-shadow: 0 0 0 0.125em transparentize($cc-green-primary, 0.8) !important;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: $cc-green-primary !important;
}

// Auth inputs
.auth_input {
  padding-left: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 2px solid $primary !important;
  background: none !important;
  box-shadow: none !important;
}

// ----------
// Padding and Margin
.has-margin-right-auto {
  margin-right: auto;
}

.has-margin-right-5 {
  margin-right: 5px;
}

.has-margin-right-15 {
  margin-right: 15px;
}

.has-margin-right-20 {
  margin-right: 20px;
}

.has-margin-right-50 {
  margin-right: 50px;
}

.has-margin-left-auto {
  margin-left: auto;
}

.has-margin-left-5 {
  margin-left: 5px;
}
.has-margin-left-15 {
  margin-left: 15px;
}
.has-margin-left-20 {
  margin-left: 20px;
}

.has-margin-bottom-0 {
  margin-bottom: 0 !important;
}

.has-margin-bottom-5 {
  margin-bottom: 5px;
}

.has-margin-bottom-10 {
  margin-bottom: 5px;
}

.has-margin-bottom-15 {
  margin-bottom: 15px;
}

.has-margin-bottom-25 {
  margin-bottom: 25px;
}

.has-margin-top-5 {
  margin-top: 5px;
}

.has-margin-top-15 {
  margin-top: 15px;
}

.has-margin-top-25 {
  margin-top: 25px;
}

.has-margin-top-0 {
  margin-top: 0px;
}

.has-margin-10 {
  margin: 10px;
}

.has-margin-y-20 {
  margin: 20px 0 !important;
}

.has-margin-y-10 {
  margin: 10px 0;
}

.has-margin-30 {
  margin: 30px;
}

.has-padding-10 {
  padding: 10px;
}

.has-padding-0 {
  padding: 0;
}

.has-padding-15 {
  padding: 15px;
}

.has-padding-20 {
  padding: 20px;
}

.has-padding-x-10 {
  padding: 0 10px;
}

.has-padding-x-20 {
  padding: 0 20px;
}

.has-padding-y-10 {
  padding: 10px 0;
}

.has-padding-y-20 {
  padding: 20px 0;
}

.has-padding-30 {
  padding: 30px;
}

.pointer {
  cursor: pointer;
}

.has-radius-10 {
  border-radius: 10px !important;
}

.is-radiusless {
  border-radius: 0 !important;
}

.is-borderless {
  border: none !important;
}

.flex-gap-5 {
  gap: 5px;
}

.flex-gap-10 {
  gap: 10px;
}

.flex-gap-15 {
  gap: 15px;
}

.flex-gap-20 {
  gap: 20px;
}

.is-fullwidth {
  width: 100%;
}

.justify-content-center {
  justify-content: center;
}

// ----------------
.ng2-tag-input.ng2-tag-input--focused {
  border-bottom: 2px solid $primary !important;
}

.textarea {
  resize: none !important;
  width: auto;
}
.textarea-fullborder {
  @extend .textarea;
  border: 2px solid $cc-gray-light !important;
  border-radius: 4px !important;
  padding: 0.5rem !important;
}
.textarea-fullborder:focus {
  border-color: $primary !important;
}

.container {
  max-width: 950px !important;
  position: static !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

//Dragula styles

/* in-flight clone */

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */

.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */

.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.filedrop-style {
  .content {
    height: 100% !important;
  }
}

.diploma_upload {
  height: 100%;

  .content {
    color: black !important;
    height: 100% !important;
  }
}

p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  opacity: 0.99;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gray-border-top {
  border-top: 2px solid $cc-gray-light;
}

.ic-hr {
  height: 5px;
  background-color: $primary;
}

.ic-color {
  color: $primary;
}

.ic-button {
  background: $primary !important;
  color: #ffffff !important;
  border-color: transparent;
  border: none !important;

  &:hover,
  &:active {
    background: #6acdbb !important;
  }
}

.shadow {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(211, 211, 211, 1) !important;
  -moz-box-shadow: 0px 0px 10px 0px rgba(211, 211, 211, 1) !important;
  box-shadow: 0px 0px 10px 0px rgba(211, 211, 211, 1) !important;
}

.bold {
  font-weight: 500 !important;
}

.input:not(.addressinput) {
  box-shadow: none !important;
}

:focus {
  outline: none !important;
}

img.source-image {
  object-fit: contain !important;
}

.list-style-none {
  list-style: none !important;
}

.is-active-nav {
  color: white !important;
  background-color: $primary;
}

//Flex helpers
.flex-column {
  flex-direction: column !important;
}

.justify-space-between {
  justify-content: space-between !important;
}

.justify-space-around {
  justify-content: space-around !important;
}

.align-center {
  align-items: center !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-space-evenly {
  justify-content: space-evenly !important;
}

// Triangle for dropdowns
.triangle {
  content: "";
  position: absolute;
  width: 0;
  top: 0px;
  right: 18px;
  height: 0;
  box-sizing: border-box;
  border: 7px solid black;
  border-color: transparent transparent white white;
  transform-origin: 0 0;
  transform: rotate(-225deg);
  box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.slice-string {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.navbar-item img {
  max-height: none !important;
}

.invalid {
  border-bottom: 2px solid $cc-orange-light !important;
}

.invalid_border {
  border: 2px solid $cc-orange-light !important;
}

.icdropzone {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  border: 2px dashed $cc-border-gray;
  overflow: hidden;
  padding: 1rem;
  min-height: 300px;
  text-align: center;

  .filedropcontent {
    color: black !important;
    width: 100% !important;
    height: 100% !important;
    cursor: pointer;
  }

  .visible-over {
    display: none;
  }

  .hidden-over {
    display: block;
  }

  image-cropper {
    border-radius: 0;
  }

  &.ngx-file-drop__drop-zone--over {
    background: $cc-green-light !important;
    border: 2px dashed $cc-green;

    .visible-over {
      display: block;
    }

    .hidden-over {
      display: none;
    }
  }
  @media (max-width: $breakpoint-tablet-max) {
    border-radius: 4px;
    background: white;
  }
}

.home-profile-header app-photo-uploader {
  width: 100%;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid $primary;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $primary transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.margintop {
  margin-top: 75px;
}

.no-shadow {
  box-shadow: none !important;
}

mwl-calendar-month-cell {
  cursor: pointer;
}

.cal-hour-segment {
  cursor: pointer;
}

.calendar-event-list-badge {
  height: 12px;
  width: 12px;
  margin-right: 10px;
  border-radius: 50%;
  background: $primary;

  &.pending {
    background-color: rgb(253, 241, 186);
  }
  &.blocker {
    background: #cccccc;
  }
}

.owl-dt-inline-container {
  box-shadow: none;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-in-range {
  background: $cc-green-low-over;
}

.owl-dt-container-info .owl-dt-container-info-active {
  color: $cc-black;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  color: rgba(255, 255, 255, 0.85);
  background-color: $cc-green-low-over;
}

.owl-dt-container-buttons {
  color: #3f51b5;
}

.message-drop {
  display: none;
  pointer-events: none;
}

.ngx-file-drop__drop-zone--over {
  .message-drop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    text-align: center;
    color: white;
    font-size: 2rem;
    padding: 4rem;
  }
}

.StripeElement {
  border: 2px solid #ebeaea;
  border-radius: 10px;
  padding-left: 10px;
}

@media only screen and (max-width: $breakpoint-tablet-min) {
  .tooltip:hover::before {
    min-width: 80vw !important;
    position: fixed;
    right: auto;
    bottom: 50%;
  }
}

.tooltip.is-tooltip-multiline::before {
  white-space: pre-line;
}

@media only screen and (max-width: $breakpoint-tablet-min) {
  .tooltip:hover:not(.is-loading)::after {
    border-color: transparent !important;
  }
}

.tooltip:hover:not(.is-loading)::after {
  margin-top: 0 !important;
  margin-left: -0.6rem !important;
  position: relative !important;
}

.qmarkCircle {
  border-radius: 50%;
  width: 0.5em;
  height: 0.5em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px !important;
  margin-left: 5px;
  margin-bottom: -6px;
  background: #fff;
  border: 1px solid #666;
  color: #666;
  font: 0.75em Arial, sans-serif;
  font-weight: bold;
  box-sizing: initial;

  &:hover,
  &:after {
    content: "?" !important;
    font-size: 11px;
  }
}

.hover-light-gray:hover {
  background-color: $cc-gray-ultralight;
  cursor: pointer;
}

.select select {
  text-rendering: auto !important;
}

.is-vbottom {
  margin-top: auto;
}

.inv-client-link {
  justify-content: space-between;
  align-items: center;
}

.clipboard:hover {
  cursor: pointer;
}
@keyframes border-pulsate {
  0% {
    border-color: #6acdbb;
  }
  50% {
    border-color: rgba(0, 255, 255, 0);
  }
  100% {
    border-color: #6acdbb;
  }
}

.linkFieldFade {
  border: 20px solid #6acdbb;
  animation: border-pulsate 2s;
}

body pre {
  background-color: #ccc;
  color: black;
  font-size: 10px;
  padding: 0.5rem;
}

.ic-breadcrumb {
  padding-bottom: 1rem;
  border-bottom: 1px solid $cc-gray-light;
  font-size: 0.875rem;

  li {
    font-size: 0.875rem;

    a {
      font-size: 0.875rem;
      color: $cc-black;
    }
  }
}

.ic-content-button {
  flex-direction: column;
  align-items: flex-start !important;
  height: auto !important;
  opacity: 0.6;
  border-left-width: 3px !important;

  &.is-selected {
    opacity: 1;
  }
}

.select2-container--default {
  z-index: 100;
}

@media print {
  .is-hidden-print {
    display: none !important;
  }
}

.ic {
  .button {
    &:focus {
      border-color: $cc-green-primary;
    }
  }

  .button.is-primary.is-outlined {
    color: $cc-black;
    border-color: $cc-green;

    &:hover {
      path {
        fill: #ffffff;
      }
    }
  }
}

.mat-tooltip {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  text-align: center;
}
.mat-tooltip-trigger-icon-wrapper {
  padding-left: 0.5em;
  padding-right: 0.5em;
  display: flex;
  align-items: center;
}

.mat-tooltip-trigger-icon {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.ic .owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: $cc-green-primary-dark;
  @include regularFont();
  font-size: 16px;
  box-shadow: unset;
}

.missing-tanslation {
  color: fuchsia !important;
}

.ng-select.custom {
  .ng-select-bottom {
    top: 120%;
  }
  .ng-select-top {
    bottom: 120%;
  }

  .ng-select-container {
    border: none !important;
    box-shadow: none !important;
    min-height: 0;
    height: auto;
    cursor: pointer;
    overflow: visible !important;

    .ng-input {
      top: 0;
      input {
        height: auto;
      }
    }

    .ng-value-container {
      color: #373736;
      background-color: transparent !important;
      border: 1px solid #dbdbdb;
      cursor: pointer;
      font-size: 12px;
      border-radius: 290486px;
      padding-top: calc(0.375em - 1px);
      padding-bottom: calc(0.375em - 1px);
      overflow: visible !important;

      &:hover {
        border-color: #b5b5b5;
      }

      .ng-placeholder {
        color: #373736;
        overflow: visible !important;
      }

      .ng-value {
        overflow: visible !important;
      }
    }

    .ng-arrow-wrapper {
      right: 30px;
      .ng-arrow {
        border: 3px solid $primary;
        border-radius: 2px;
        border-right: 0;
        border-top: 0;
        content: " ";
        display: block;
        height: 0.625em;
        margin-top: -0.4375em;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: rotate(-45deg) scale(0.9);
        transform-origin: center;
        width: 0.625em;
      }
    }
  }
}

.mat-mdc-icon-button {
  // we don't want sofia-pro font for the icons. For some reason they were overriding with !important for all buttons
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: white;
}
@keyframes mat-spinner {
  to {
    transform: rotate(360deg);
  }
}

.mat-spinner::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  border-top-color: $cc-green;
  animation: mat-spinner 0.6s linear infinite;
}

.hide-btn-text {
  color: transparent !important;
}

.elevation_2 {
  // Adds a shadow for elevation level 2 with default color and full opacity:
  // for some reason, the mat-menu is not applying elevation by default
  @include mat.elevation(2);
}

.str-chat {
  --str-chat__primary-color: #4cc2ac; /* $cc-green-primary */
  --str-chat__active-primary-color: #3aa18e; /* $cc-green-primary-dark */
  --str-chat__surface-color: #f5f4f4; /* $cc-gray-ultralight */
  --str-chat__secondary-surface-color: #f5f4f4; /* $background-auth */
  --str-chat__primary-surface-color: #ddf4ef; /* $cc-green-light */
  --str-chat__primary-surface-color-low-emphasis: hsl(
    167,
    51%,
    95%
  ); /* $cc-green-low-over */
  --str-chat__message-max-width: calc(
    var(--str-chat__spacing-px) * 600
  );
  --str-chat__font-family: "General Sans", sans-serif;
  --str-chat__body2-text: 16px;
  --str-chat__message-bubble-border-radius: 4px;
  --str-chat__message-with-attachment-max-width: calc(
    var(--str-chat__spacing-px) * 400
  );
  --str-chat__disabled-color: #ebeaea; /* $cc-gray */
}

.str-chat__message-input {
  padding: 0;

  .str-chat__file-input-container {
    border-radius: 0;
  }
}

//below here to make mat date picker work with bulma
.cdk-visually-hidden {
  display: none;
}
.mat-calendar-table-header th  {
  text-align: center !important;
}
.mat-calendar-body-cell-container {
  text-align: center !important;
}
.mat-calendar-body-cell:focus .mat-focus-indicator::before {
  display: none;
}
