@mixin box-shadow() {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
}
@mixin box-shadow-border() {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
@mixin border-radius() {
  border-radius: 4px;
}

@mixin semiBoldFont() {
  font-family: 'GeneralSans-SemiBold', sans-serif;
  font-weight: 600;
}

@mixin legacySemiBold() {
  //This used to be semi bold on the old font, but it's too heavy on the new font. This should be the same as medium, but exists for documentation purposes.
  font-family: 'GeneralSans-Medium', sans-serif;
  font-weight: 500;
}

@mixin regularFont() {
  font-family: 'GeneralSans-Regular', sans-serif;
  font-weight: 400;
}

@mixin mediumFont() {
  font-family: 'GeneralSans-Medium', sans-serif;
  font-weight: 500;
}

@mixin lightFont() {
  font-family: 'GeneralSans-Light', sans-serif;
  font-weight: 300;
}
